import Rails from "@rails/ujs"

var loadMore = function() {
	var moreLink = document.getElementById("more_link");
	if (!moreLink) {
		console.log("No more pages");
		// document.getElementById("pagination").innerHTML = "";
		incrementalStop();
		return;
	}

	if (moreLink.dataset.loading) {
		return;
	}

	var viewBottom = document.documentElement.scrollTop + document.documentElement.clientHeight * 1.25;
	var list = document.getElementById("bookmarks");
	var listBottom = list.offsetTop + list.clientHeight;

	if (viewBottom >= listBottom) {
		console.log("Loading next page");

		moreLink.dataset.loading = true;

		Rails.ajax({
			url: moreLink.dataset.href,
			type: "get",
			dataType: "json",
			success: function(data) {
				console.log(`Adding page ${data["page"]}`);
				document.getElementById("bookmarks").insertAdjacentHTML("beforeend", data["bookmarks"]);
				document.getElementById("pagination").innerHTML = data["pagination"];
				console.log(`Added page ${data["page"]}`);

				loadMore();
			},
			error: function(data) {
				console.log(`Failed to load next page: ${data}`);
			}
		})
	}
};

export function incrementalStart() {
	window.addEventListener("resize", loadMore);
	window.addEventListener("scroll", loadMore);
	window.addEventListener("load", loadMore);
	console.log("Automatic page loading enabled");
};

export function incrementalStop() {
	window.removeEventListener("resize", loadMore);
	window.removeEventListener("scroll", loadMore);
	window.removeEventListener("load", loadMore);
	console.log("Automatic page loading disabled");
};

function loadTitle(src, dst, msg, uri) {
	if (src.dataset.loading) {
		return;
	}

	dst.disabled = true;

	src.dataset.loading = true;
	src.dataset.lookup = "pending";
	msg.innerText = "";

	Rails.ajax({
		url: "/lookup/url.json",
		type: "get",
		data: "uri=" + encodeURIComponent(uri)
			+ "&user_agent=" + encodeURIComponent(navigator.userAgent),
		dataType: "json",
		success: function(data) {
			dst.disabled = false;

			console.log(`Title for URL: ${data["title"]}`);

			if (data["title"]) {
				dst.value = data["title"];
			}

			delete src.dataset.loading;
			src.dataset.lookup = "success";
		},
		error: function(data) {
			dst.disabled = false;

			if (data["error"]) {
				data = data["error"];
			}

			console.log(`Failed to get title for URL: ${data}`);

			msg.innerText = data;

			delete src.dataset.loading;
			src.dataset.lookup = "error";
		}
	})
};

function onPasteURI(src, dst, msg, event) {
	var uri = event.clipboardData.getData("text");
	if (uri.match(/https?:\/\//)) {
		console.log(`HTTP URL pasted: ${uri}`);

		loadTitle(src, dst, msg, uri);
	}
};

function onInputURI(src, msg, event) {
	if (src.dataset.lookup && !src.dataset.loading) {
		console.log(`Clearing lookup result`);
		delete src.dataset.lookup;
		msg.innerText = "";
	}
};

function onRefreshURI(src, dst, msg, event) {
	var uri = src.value;
	if (uri.match(/https?:\/\//)) {
		console.log(`Refreshing title for: ${uri}`);

		loadTitle(src, dst, msg, uri);
	}
};

function onOpenURI(dst, event) {
	var uri = dst.value;
	console.log(`Opening: ${uri}`);
	window.open(uri);
};

export function setupPasteURI(src, dst, msg, refresh) {
	src = document.getElementById(src);
	dst = document.getElementById(dst);
	msg = document.getElementById(msg);
	refresh = document.getElementById(refresh);
	src.addEventListener("paste", onPasteURI.bind(null, src, dst, msg));
	src.addEventListener("input", onInputURI.bind(null, src, msg));
	refresh.addEventListener("click", onRefreshURI.bind(null, src, dst, msg));
	console.log("Automatic HTTP URL title handling enabled");
};

export function setupOpenURI(src, dst) {
	src = document.getElementById(src);
	dst = document.getElementById(dst);
	var input = document.createElement("input");
	input.type = "button";
	input.value = "Open URI";
	src.appendChild(input);
	input.addEventListener("click", onOpenURI.bind(null, dst));
	console.log("Open URI button added");
};
